import recallService from '../../../services/notisphere/recall'
import orgServices from '../../../services/notisphere/organization'
import { Notisphere_Recalls_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../misc/tableHeaders'
import { COMMUNICATION_TYPE } from '../../../misc/commons'
export default {
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            items: [],
            item: {},
            status: '',
            gridApi: null,
            columnApi: null,
            search: {
                status: null,
                supplier: null,
                recallId: null,
                name: null,
                classification: null,
                date: null,
                completeddate: null,
                days_open: null,
                source: null,
                number_of_providers: null,
                sitecount: null,
                organizationId: null,
            },
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            accountFilterOptions: [],
            selectUserType: 'PROVIDER',
            accountDropdownValue: -1,
        }
    },
    beforeMount() {
        this.columnDefs = Notisphere_Recalls_AgGrid
    },
    created() {
        this.rowModelType = 'serverSide'
        this.getOrganizationList({'type': 'PROVIDER'})
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Notisphere_Login.Recall_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Notisphere_Login?.Recall_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onCellClicked(params) {
            if (params.colDef.field == 'status') {
                this.addMoreRespClick(params.data)
                return
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'view') {
                    this.viewRecall(params.data)
                }
                else if (params.event.target.dataset.action === 'copy') {
                    this.copyRecall(params.data)
                }
                return
            }else{
                this.viewRecall(params.data)
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Notisphere_Login.Recall_Filters=event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Notisphere_Login?.Recall_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },

        ViewClick(val) {
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(val)
            })
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            // params.api.sizeColumnsToFit()
            that.search.selectedOrgId = this.accountDropdownValue && this.accountDropdownValue !== -1 ? this.accountDropdownValue : null
            that.search.userType = this.selectUserType
            const datasource = {
                getRows(params) {
                    that.search.status_provider = params.request?.filterModel?.status_provider?.values
                    that.search.creator_name = params.request?.filterModel?.creator_name?.filter
                    that.search.communicationUID = params.request?.filterModel?.communicationUID?.filter
                    that.search.name = params.request?.filterModel?.name?.filter
                    that.search.classification = params.request?.filterModel?.classification?.filter
                    that.search.status = params.request?.filterModel?.status?.values
                    that.search.communicationDate = params.request?.filterModel?.communicationDate?.dateFrom?.split(' ')[0]
                    that.search.completeddate = params.request?.filterModel?.completeddate?.dateFrom?.split(' ')[0]
                    that.search.days_open = params.request?.filterModel?.days_open?.filter
                    that.search.source = params.request?.filterModel?.source?.values
                    that.search.number_of_providers = params.request?.filterModel?.number_of_providers?.filter
                    that.search.sitecount = params.request?.filterModel?.sitecount?.filter
                    that.search.isCustomizedContent = params.request?.filterModel?.isCustomizedContent?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    recallService.findRecall({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search,
                        communicationType: COMMUNICATION_TYPE.RECALL
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = []
                            for (var i = 0; i < resp.data.d.c.length; i++) {
                                var checkexist = recallsarray.filter(s => s.id == resp.data.d.c[i].id)
                                if (checkexist.length == 0) {
                                    recallsarray.push(resp.data.d.c[i])
                                }
                            }
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)

        },

        addMoreRespClick(val, mode) {
            if (val.status == 'ERROR') {
                this.status = val.status
                this.status = this.status == 'Bounce' ? 'ERROR' : this.status
                this.item = val
                this.$refs.modal.show()
            }
        },
        viewRecall(item) {
            this.$router.push({ name: 'noti_RecallView', params: { id: item.id } })
        },
        copyRecall(item) {
            this._showConfirm('Are you sure you want to make a copy of this recall?', { title: 'Copy Recall', variant: 'warning' }).then(confirm => {
                if (confirm) {
                    recallService.copyRecallById(item.id).then(resp => {
                        if (!resp.error) {
                            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                            this._showToast('Recall Copied Successfully!')
                        }
                    })
                }
            })
        },
        deleteRecall(item, e = null) {
            this._showConfirm('Are you sure you want to delete the recall, this cannot be undone?', { title: 'Delete Recall', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    recallService.deleteRecall(item.id, item.supplier_id).then(resp => {
                        if (!resp.error) {
                            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
                            this._showToast('Recall Deleted!')
                        }
                    })
                }
            })
            if (e) {
                e.stopPropagation()
            }
        },

        findRECSVReport() {
            recallService.downloadCSVRecall({
                ...this.searchQuery,
                sortField: this.searchQuery.colId,
                sortDirection: this.searchQuery.sort,
                ...this.search
            }).then(resp => {
            })
        },
        eventSummaryReport(type) {
            recallService.downloadEventSummary({
                ...this.searchQuery,
                sortField: this.searchQuery.colId,
                sortDirection: this.searchQuery.sort,
                ...this.search,
            }, type).then(resp => {
            })
        },
        findREXLSXReport() {
            recallService.downloadXLSXRecall({
                ...this.searchQuery,
                sortField: this.searchQuery.colId,
                sortDirection: this.searchQuery.sort,
                ...this.search
            }).then(resp => {
            })
        },
        onTypeFilterChange(val) {
            this.selectUserType = val.type
            this.fetchData(val)
            this.getOrganizationList(val)
        },
        onAccountFilterChange(val) {
            this.accountDropdownValue = val?.organizationId
            this.fetchData(val)
        },
        getOrganizationList(val) {
            orgServices.GetOrganizationListByType(val?.type === 'PROVIDER' ? 'PROVIDER' : 'SUPPLIER').then(resp => {
                if (!resp.error) {
                    this.accountFilterOptions = resp.data.d.map((item) => {
                        return { value: item.organizationId, text: item.name }
                    })
                    this.accountFilterOptions.unshift({ value: -1, text: 'All' })
                }
            })
        },
    }
}